<template>
    <DesignerDetailView v-editable="blok" :data="designer" />
</template>

<script setup lang="ts">
import DesignerDetailType from '~/types/DesignerDetailType';
import DesignerSocialLinkType from '~/types/DesignerSocialLinkType';
import ImageType from '~/types/ImageType';
import LinkType from '~/types/LinkType';
import FaqCardGroupType from '~/types/FaqCardGroupType';
import CollapseCardType from '~/types/CollapseCardType';
import { ProductVariation } from '~/types/product';
import DesignerDetailView from '~/components/page-building/design-resource-gallery/DesignerDetailView.vue';
import { imageFromStoryblokAsset, richTextToHtml } from '~/utils/storyblok';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const designer = computed<DesignerDetailType>(() => {
    const variations: ProductVariation[] = props.blok.products
        ? props.blok.products.map((product: any) => {
              return {
                  id: product.product_variation.content.database_product?.id,
                  sku: product.product_variation.content.database_product?.sku,
                  image: imageFromStoryblokAsset(product.image),
                  name: product.description,
                  description: richTextToHtml(product.description),
                  details: product.product_variation.content.details
                      ? product.product_variation.content.details.map(
                            (detail: any) => {
                                return {
                                    label: detail.label,
                                    content: richTextToHtml(detail.content),
                                };
                            },
                        )
                      : [],
                  vEditable: product.product_variation.content,
              };
          })
        : [];

    const socialLinks = props.blok.social_links?.map((socialLink: any) => {
        return {
            link: {
                label: '',
                url: socialLink.link.cached_url,
            } as LinkType,
            icon: socialLink.icon,
        } as DesignerSocialLinkType;
    });

    return {
        title: props.blok.title,
        designerName: props.blok.designer_name,
        designerLocation: props.blok.designer_location,
        designerPhoto: {
            title: props.blok.designer_photo.name,
            alt: props.blok.designer_photo.alt,
            src: props.blok.designer_photo.filename,
        } as ImageType,
        expertise:
            props.blok.expertise && props.blok.expertise != ''
                ? props.blok.expertise.split('\n')
                : [],
        designStyle:
            props.blok.design_style && props.blok.design_style != ''
                ? props.blok.design_style.split('\n')
                : [],
        bio: props.blok.bio,
        designerEmail: props.blok.designer_email,
        socialLinks: socialLinks,
        variations: variations,
        displayFAQ: props.blok.display_faq,
        faqs: {
            heading: props.blok.faq_title,
            id: '1',
            cards: props.blok.faqs
                ? props.blok.faqs.map((faq: any, index: number) => {
                      return {
                          title: faq.question,
                          content: richTextToHtml(faq.answer),
                          id: index.toString(),
                      } as CollapseCardType;
                  })
                : [],
            expanded: true,
        } as FaqCardGroupType,
        vEditable: props.blok,
    };
});
</script>
