<template>
    <BlockContainer
        background-color="white"
        bottom-angle="left"
        :angle-background-color="data.displayFAQ ? 'light-blue' : 'white'"
        slot-class="mt-20 pb-28"
    >
        <div class="md:flex">
            <div class="md:w-1/2 md:pr-8 xl:pr-14">
                <ProductViewCarousel
                    ref="carouselRef"
                    :product="data"
                    @selected-product-variation="selectedProductVariation"
                />
            </div>

            <div class="mt-12 md:mt-0 md:w-1/2 md:pl-8 xl:pl-14">
                <div class="flex flex-row">
                    <div class="mr-8 h-[120px] w-[120px] shrink-0">
                        <img
                            v-if="data.designerPhoto"
                            :src="data.designerPhoto.src"
                            :alt="data.designerPhoto.alt"
                            class="h-[120px] w-[120px] rounded-full"
                        />
                    </div>
                    <div>
                        <h1>{{ data.title }}</h1>
                        <div class="pt-1 font-bold uppercase text-drip">
                            {{ data.designerName }}
                        </div>
                        <p class="pt-1">
                            {{ data.designerLocation }}
                        </p>
                    </div>
                </div>

                <div
                    v-if="data.expertise.length > 0"
                    class="mb-2 mt-6 uppercase"
                >
                    EXPERTISE
                </div>
                <TagGroup :tags="data.expertise" />

                <div
                    v-if="data.designStyle.length > 0"
                    class="mb-2 mt-6 uppercase"
                >
                    DESIGN STYLE
                </div>
                <TagGroup :tags="data.designStyle" />

                <div
                    v-if="data.bio && data.bio != ''"
                    class="mb-2 mt-6 uppercase"
                >
                    PERSONAL BIO
                </div>
                <div class="whitespace-pre-wrap">{{ data.bio }}</div>

                <div class="mb-2 mt-6 uppercase">FIND ME ONLINE</div>
                <div class="flex flex-row">
                    <a
                        v-for="socialLink in data.socialLinks"
                        :key="socialLink.link.url"
                        :href="socialLink.link.url"
                        target="_blank"
                        class="mr-4"
                    >
                        <div
                            class="flex h-[44px] w-[44px] flex-col justify-center rounded-xl bg-grind transition-colors hover:bg-brew"
                        >
                            <Icon
                                :name="socialLink.icon"
                                class="flex justify-center text-xl text-white"
                            />
                        </div>
                    </a>
                </div>
                <a
                    class="btn btn-primary mt-12 w-full"
                    :href="'mailto:' + data.designerEmail"
                >
                    Get in Touch
                </a>
                <NuxtLink
                    class="btn-secondary mt-6 w-full"
                    :to="'/get-quote#product=' + variationId"
                >
                    Quote this Product
                </NuxtLink>
            </div>
        </div>
    </BlockContainer>
    <BlockContainer
        v-if="data.displayFAQ"
        background-color="light-blue"
        bottom-angle="right"
        angle-background-color="white"
        slot-class="mt-20 pb-28"
    >
        <FaqCategoryGroup
            :data="data.faqs"
            :related-faqs="false"
            :two-columns="true"
        />
    </BlockContainer>
</template>

<script setup lang="ts">
import DesignerDetailType from '~/types/DesignerDetailType';
import ProductViewCarousel from '~/components/page-building/product-view/components/ProductViewCarousel.vue';
import FaqCategoryGroup from '~/components/page-building/faq/FaqCategoryGroup.vue';

const props = defineProps<{
    data: DesignerDetailType;
}>();

const state = reactive({
    variationId: props.data.variations[0].id as number,
});

const { variationId } = toRefs(state);

const carouselRef = ref<InstanceType<typeof ProductViewCarousel> | null>(null);

const selectedProductVariation = (variation: number) => {
    variationId.value = variation;
};
</script>
